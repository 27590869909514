export function urlForRoute(
  route: string | { pathname: string; query?: Record<string, string> }
) {
  if (typeof route === "string") return route

  const queryString = route.query
    ? `?${new URLSearchParams(route.query).toString()}`
    : ""
  return `${route.pathname}${queryString}`
}

export const routes = {
  root: "/",
  auth: {
    login: "/auth/login",
    loginError: "/auth/login/error-state",
    sso: {
      provider: {
        callback: (provider: string) => `/auth/sso/${provider}/callback`,
      },
    },
  },
  dashboard: "/dashboard",
  rhythm: {
    detail: (rhythmId: string) => `/rhythms/${rhythmId}`,
    instance: (rhythmId: string, instanceId: string) => ({
      pathname: `/rhythms/${rhythmId}`,
      query: {
        details: "true",
        detailsEntityType: "WorkflowInstance",
        detailsEntityId: `${rhythmId}_${instanceId}`,
      },
    }),
  },
  tft: {
    detail: (tftId: string) => `/t5ts/${tftId}`,
    submission: (tftId: string, submissionId: string) => ({
      pathname: `/t5ts/${tftId}`,
      query: {
        details: "true",
        detailsEntityType: "TftArtifact",
        detailsEntityId: `${tftId}_${submissionId}`,
      },
    }),
  },
  okrs: {
    list: "/okrs",
    detail: (okrId: string) => `/okrs/${okrId}`,
  },
  actions: {
    list: "/actions",
  },
  liveUpdates: {
    list: "/live-updates",
  },
  recommendations: {
    list: "/recommendations",
  },
  playbooks: {
    list: "/playbooks",
    okrs: {
      details: "/playbooks/okrs/details",
    },
    t5t: {
      details: "/playbooks/t5t/details",
    },
  },
  admin: {
    root: "/admin",
  },
  teams: {
    list: "/teams",
    detail: (teamId: string) => `/teams/${teamId}`,
  },
  explorerView: {
    detail: (tenantId: string, viewId: string) => ({
      pathname: "/okrs",
      query: {
        viewId: `${tenantId}_${viewId}`,
      },
    }),
  },
} as const
