import { ssoProvider } from "@/services/auth/auth"
import { useMutation } from "@tanstack/react-query"

const useLogin = () => {
  const ssoProviderMutation = useMutation({
    mutationFn: (provider: string) => {
      return ssoProvider(provider)
    },
    onSuccess: (data) => {
      window.location.href = data.authorize_url
    },
  })

  return {
    ssoProviderMutation,
  }
}

export { useLogin }
