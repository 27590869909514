import { routes } from "@/config/routes"

import { fetchJson } from "../utils"
import {
  type SSOCallbackResponse,
  type SSOProviderResponse,
} from "./auth.types"

const BASE_API_URL = "/api/auth/sso"

/**
 * Requests Single Sign-On (SSO) provider information.
 */
export const ssoProvider = async (provider: string) => {
  const redirectUri = `${window.location.origin}${routes.auth.sso.provider.callback(provider)}`
  const result = await fetchJson<SSOProviderResponse>(
    `${BASE_API_URL}/${provider}?redirect_uri=${encodeURIComponent(redirectUri)}`,
    {
      method: "POST",
      body: { provider },
    }
  )
  return result
}

/**
 * Handles the callback from Single Sign-On (SSO) providers.
 */
export const ssoCallback = async (queryString: string, provider: string) => {
  const result = await fetchJson<SSOCallbackResponse>(
    `${BASE_API_URL}/${provider}/callback?${queryString}`,
    {
      method: "GET",
    }
  )
  return result
}
